import { RouteConfig } from "vue-router";

const timelineRoutes: RouteConfig[] = [
  {
    path: "",
    name: "timeline",
    meta: {
      title: "meta.timeline",
      authorize: [],
      tile: "timeline",
    },
    component: () =>
      import(/* webpackChunkName: "timeline" */ "@/components/timeline/tiles/TimelineTile.vue"),
  },
  {
    path: "upload",
    name: "uploadDocument",
    meta: {
      title: "meta.uploadDocument",
      authorize: [],
      tile: "timeline",
    },
    component: () =>
      import(
        /* webpackChunkName: "timelineUpload" */ "@/components/timeline/tiles/DocumentUploadTile.vue"
      ),
  },
  {
    path: ":id",
    name: "timelineDetail",
    props: true,
    meta: {
      title: "meta.timelineDetail",
      authorize: [],
      scrollToTop: true,
      tile: "timeline",
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */ "@/components/timeline/tiles/TimelineDetailsTile.vue"
      ),
  },
  {
    path: ":id/edit",
    name: "timelineEdit",
    meta: {
      title: "meta.timelineEdit",
      authorize: [],
      scrollToTop: true,
      tile: "timeline",
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */ "@/components/timeline/tiles/DocumentUploadTile.vue"
      ),
  },
  {
    path: ":id/new",
    name: "uploadNewDocument",
    meta: {
      title: "meta.uploadNewDocument",
      authorize: [],
      scrollToTop: true,
      tile: "timeline",
    },
    component: () =>
      import(
        /* webpackChunkName: "timelineUpload" */ "@/components/timeline/tiles/DocumentUploadTile.vue"
      ),
  },
];

export default timelineRoutes;
