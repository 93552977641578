import Vue from "vue";
import Notifications from "vue-notification";
import Vuelidate from "vuelidate";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./assets/styles/tailwind.pcss";
import "./registerComponentHooks";
import titleMixin from "./mixins/titleMixin";
import sessionMixin from "./mixins/sessionMixin";

Vue.config.productionTip = false;

Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.mixin(titleMixin);
Vue.mixin(sessionMixin);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
