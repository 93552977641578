
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import AccountModule, { LocalStorageItemKeys } from "@/store/modules/account";
import DocumentModule from "@/store/modules/document";
import AuditEventModule from "@/store/modules/auditEvents";
import ConsentModule from "@/store/modules/consent";
import HCProviderModule from "@/store/modules/hcprovider";
import NotificationsModule from "@/store/modules/notifications";

// eslint-disable-next-line no-unused-vars
import { Role, UnauthorizedError } from "@/global";
import config from "@/config";
import { NotificationOptions } from "vue-notification";

// MTCaptcha Integration and config setting
(window as any).mtcaptchaConfig = config.mtcaptchaConfig;
(function () {
  var mt_service = document.createElement("script");
  mt_service.async = true;
  mt_service.src = "https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js";
  (
    document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]
  ).appendChild(mt_service);
  var mt_service2 = document.createElement("script");
  mt_service2.async = true;
  mt_service2.src = "https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js";
  (
    document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]
  ).appendChild(mt_service2);
})();
// *End* MTCaptcha Integration and config setting

/* eslint-disable */
enum NotificationType {
  SUCCESS = "success",
  WARN = "warn",
  ERROR = "error",
}

@Component({
  components: { Navbar, Footer },
})
export default class App extends Vue {
  private accountState = getModule(AccountModule);

  created() {
    const shortLocale = this.$i18n.locale.split("-")[0];
    (window as any).mtcaptchaConfig.lang = shortLocale;
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", shortLocale);

    window.addEventListener("phellow:event:logout", () => {
      const accountState = getModule(AccountModule);
      const auditEventState = getModule(AuditEventModule);
      const consentState = getModule(ConsentModule);
      const documentState = getModule(DocumentModule);
      const hcproviderState = getModule(HCProviderModule);
      const notificationState = getModule(NotificationsModule);

      const idToken = accountState.idToken;

      accountState.logout();
      auditEventState.clearAuditEvents();
      consentState.clearConsent();
      documentState.clearDocuments();
      hcproviderState.clearCurrentHCP();
      notificationState.clearNotifications();

      if (idToken) {
        let logoutUrl = config.openid.logoutEndpoint;
        logoutUrl += "?id_token_hint=" + idToken;
        logoutUrl += "&post_logout_redirect_uri=" + config.openid.redirectUri;
        window.location.href = logoutUrl;
      } else {
        this.$router.push({ name: "login" });
      }
    });
  }

  logout() {
    window.dispatchEvent(new CustomEvent("phellow:event:logout"));
  }

  updateSession() {
    this.accountState.updateSessionTimeout();
  }

  private get disableSessionContraints() {
    return config.session.disableSessionConstraints;
  }

  private get availableLanguages(): string[] {
    return config.availableLanguages;
  }

  private get role(): Role {
    return this.accountState.role;
  }

  // private get expiresAt(): string | null {
  //   return this.accountState.expiresAt;
  // }

  private get hasValidToken() {
    return this.accountState.hasValidToken;
  }

  private deactivateEmergencyAccess() {
    const documentState = getModule(DocumentModule);

    this.accountState.setHasEmergencyAccess(false);
    this.accountState.setEmergencyAccessRequested(false);
    this.accountState.clearXUAToken();
    documentState.clearDocuments();
    this.accountState.setNoAccessToThisEPD(false);
    this.accountState
      .getAuthenticationHeader()
      .then(() => {
        if (this.$route.name == "timeline") {
          documentState
            .fetchDocuments()
            .then(() => {
              this.accountState.setNoAccessToThisEPD(false);
              this.$notify({
                text: this.$t("toasts.documentStore.documentsFetchSuccess").toString(),
                type: "success",
                data: { srOnly: true },
              });
            })
            .catch((error) => {
              if (error instanceof UnauthorizedError) {
                this.accountState.setNoAccessToThisEPD(true);
              } else {
                this.accountState.setNoAccessToThisEPD(false);
                this.$notify({
                  text: this.$t("toasts.documentStore.documentsFetchFail").toString(),
                  type: "error",
                  duration: -1,
                });
              }
            });
        } else {
          this.$router.push({ name: "timeline", params: { _reload: "true" } });
        }
      })
      .catch(() => {
        this.accountState.clearSelectedPatient();
        this.accountState.clearPatientSPID();
        this.accountState.clearPatientLocalID();
        documentState.clearDocuments();
        this.$router.push({ name: "patients" });
      });
  }

  notificationClass(notification: NotificationOptions & { data: { srOnly?: boolean } }): object {
    switch (notification.type) {
      case NotificationType.SUCCESS:
        return { success: true, "sr-only": notification.data?.srOnly };
      case NotificationType.WARN:
        return { warn: true };
      case NotificationType.ERROR:
        return { error: true };

      default:
        return {};
    }
  }

  notificationRole(notification: NotificationOptions): string {
    switch (notification.type) {
      case NotificationType.ERROR:
        return "alert";
      default:
        return "status";
    }
  }
}
