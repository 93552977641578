import { RouteConfig } from "vue-router";
import { Role } from "@/global";

const accessLogsRoutes: RouteConfig[] = [
  {
    path: "",
    name: "accessLogs",
    meta: {
      title: "meta.accessLogs",
      authorize: [Role.Patient, Role.Representative],
      tile: "accessLogs",
    },
    component: () => import(/* webpackChunkName: "accessLogs" */ "@/components/atna/ATNATile.vue"),
  },
];

export default accessLogsRoutes;
