import {
  PolicySetIdReferenceValue_AccessLevel,
  PolicySetIdReferenceValue_EmergencyAccessLevel,
  PolicySetIdReferenceValue_ProvideLevel,
} from "@/store/modules/consent";
import dayjs, { Dayjs } from "dayjs";
// eslint-disable-next-line no-unused-vars
import { R4 } from "@ahryman40k/ts-fhir-types";
import { Timing_RepeatDurationUnitKind } from "@ahryman40k/ts-fhir-types/lib/R4";

export interface IConsent {
  hcp?: R4.IOrganization | R4.IPractitionerRole;

  readonly templateID: string | null;
  readonly resourceID: string | null;
  readonly hcpID: string | null;
  readonly isPractitioner: boolean;
  readonly canDelegate: boolean;
  readonly policySetID: string | null;
  readonly accessLevel?: string;
  readonly expirationDate?: Dayjs;

  fhir(): string;
}

export class ExtendedConsent implements IConsent {
  private consent: R4.IConsent;
  public hcp?: R4.IOrganization | R4.IPractitionerRole;

  constructor(consent: R4.IConsent) {
    this.consent = consent;
  }

  public get resourceID(): string | null {
    return this.consent.patient?.identifier?.value || null;
  }

  public get isPractitioner(): boolean {
    for (const actor of this.consent.provision?.actor || []) {
      for (const type of actor.reference?.identifier?.type?.coding || []) {
        if (type.code == "urn:gs1:gln") {
          return true;
        }
      }
    }
    return false;
  }

  public get hcpID(): string | null {
    for (const actor of this.consent.provision?.actor || []) {
      for (const type of actor.reference?.identifier?.type?.coding || []) {
        if (
          type.code == "urn:gs1:gln" ||
          type.code == "urn:oasis:names:tc:xspa:1.0:subject:organization-id"
        ) {
          return actor.reference!.identifier!.value || null;
        }
      }
    }
    return null;
  }

  public get policySetID(): string | null {
    for (const identifier of this.consent.identifier!) {
      for (const type of identifier.type?.coding || []) {
        if (type.code == "policySetId") {
          return identifier.value || null;
        }
      }
    }

    return null;
  }

  public get templateID(): string | null {
    for (const identifier of this.consent.identifier || []) {
      for (const type of identifier.type?.coding || []) {
        if (type.code == "templateId") {
          return identifier.value || null;
        }
      }
    }

    return null;
  }

  public get accessLevel(): PolicySetIdReferenceValue_AccessLevel {
    return this.consent.policyRule!.coding![0]!.code as PolicySetIdReferenceValue_AccessLevel;
  }

  public get expirationDate(): Dayjs {
    return dayjs(this.consent.provision?.period?.end);
  }

  public get canDelegate(): boolean {
    return this.accessLevel.includes("delegation");
  }

  fhir(): string {
    return JSON.stringify(this.consent);
  }
}

export function PolicySetId(consent: R4.IConsent): string | null {
  for (const identifier of consent.identifier!) {
    for (const type of identifier.type?.coding || []) {
      if (type.code == "policySetId") {
        return identifier.value || null;
      }
    }
  }

  return null;
}

export function HCPIdentifier(consent: R4.IConsent): string | null {
  for (const actor of consent.provision?.actor || []) {
    for (const type of actor.reference?.identifier?.type?.coding || []) {
      if (
        type.code == "urn:gs1:gln" ||
        type.code == "urn:oasis:names:tc:xspa:1.0:subject:organization-id"
      ) {
        return actor.reference!.identifier!.value || null;
      }
    }
  }

  return null;
}

export function AccessLevel(consent: R4.IConsent): string | null {
  return consent.policyRule!.coding![0]!.code || null;
}

/**
 *  Create a FHIR Consent for a Practitioner - based on the official template - setting their document access.
 *
 * @param policySetID The PolicySet PolicySetId (with "urn:uuid:" prepended)
 * @param level The access level the HCP is granted
 * @param eprSpid The EPR SPID of the relevant patient
 * @param gln The GLN of the practitioner
 * @param expirationDate The expiration date of this policy
 *
 * @returns FHIR Consent Resource (R4)
 */
export function PractitionerAccess(
  policySetID: string,
  level: PolicySetIdReferenceValue_AccessLevel,
  eprSpid: string,
  gln: string,
  expirationDate: string
): IConsent {
  return new ExtendedConsent({
    resourceType: "Consent",
    meta: {
      profile: ["http://fhir.ch/ig/ch-epr-ppqm/StructureDefinition/PpqmConsent"],
    },
    identifier: [
      {
        type: {
          coding: [
            {
              system: "http://fhir.ch/ig/ch-epr-ppqm/CodeSystem/PpqmConsentIdentifierType",
              code: "policySetId",
            },
          ],
        },
        value: policySetID,
      },
      {
        type: {
          coding: [
            {
              system: "http://fhir.ch/ig/ch-epr-ppqm/CodeSystem/PpqmConsentIdentifierType",
              code: "templateId",
            },
          ],
        },
        value: "301",
      },
    ],
    status: "active",
    scope: {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/consentscope",
          code: "patient-privacy",
        },
      ],
    },
    category: [
      {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
            code: "INFA",
          },
        ],
      },
    ],
    patient: {
      identifier: {
        system: "urn:oid:2.16.756.5.30.1.127.3.10.3",
        value: eprSpid,
      },
    },
    policyRule: {
      coding: [
        {
          code: level,
        },
      ],
    },
    provision: {
      period: {
        end: expirationDate,
      },
      actor: [
        {
          role: {
            coding: [
              {
                system: "urn:oid:2.16.756.5.30.1.127.3.10.6",
                code: "HCP",
              },
            ],
          },
          reference: {
            identifier: {
              type: {
                coding: [
                  {
                    code: "urn:gs1:gln",
                  },
                ],
              },
              value: gln,
            },
          },
        },
      ],
      purpose: [
        {
          system: "urn:oid:2.16.756.5.30.1.127.3.10.5",
          code: "NORM",
        },
      ],
    },
  } as R4.IConsent);
}

/**
 * Create a FHIR Consent for an Organization - based on the official template - setting its document access.
 *
 * @param policySetID The PolicySet PolicySetId (with "urn:uuid:" prepended)
 * @param level The access level the HCP is granted
 * @param eprSpid The EPR SPID of the relevant patient
 * @param organizationID The OID of the organization (without "urn:oid:")
 * @param expirationDate The expiration date of this policy
 *
 * @returns FHIR Consent Resource (R4)
 */
export function OrganizationAccess(
  policySetID: string,
  level: PolicySetIdReferenceValue_AccessLevel,
  eprSpid: string,
  organizationID: string,
  expirationDate: string
): IConsent {
  return new ExtendedConsent({
    resourceType: "Consent",
    meta: {
      profile: ["http://fhir.ch/ig/ch-epr-ppqm/StructureDefinition/PpqmConsent"],
    },
    text: {
      status: "empty",
      div: '&lt;div xmlns="http://www.w3.org/1999/xhtml";&gt;&lt;p&gt;Template 302 -- Access level for a group of healthcare professionals&lt;/p&gt;&lt;/div&gt;',
    },
    identifier: [
      {
        type: {
          coding: [
            {
              system: "http://fhir.ch/ig/ch-epr-ppqm/CodeSystem/PpqmConsentIdentifierType",
              code: "policySetId",
            },
          ],
        },
        value: policySetID,
      },
      {
        type: {
          coding: [
            {
              system: "http://fhir.ch/ig/ch-epr-ppqm/CodeSystem/PpqmConsentIdentifierType",
              code: "templateId",
            },
          ],
        },
        value: "302",
      },
    ],
    status: "active",
    scope: {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/consentscope",
          code: "patient-privacy",
        },
      ],
    },
    category: [
      {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
            code: "INFA",
          },
        ],
      },
    ],
    patient: {
      identifier: {
        system: "urn:oid:2.16.756.5.30.1.127.3.10.3",
        value: eprSpid,
      },
    },
    policyRule: {
      coding: [
        {
          code: level,
        },
      ],
    },
    provision: {
      period: {
        end: expirationDate,
      },
      actor: [
        {
          role: {
            coding: [
              {
                system: "urn:oid:2.16.756.5.30.1.127.3.10.6",
                code: "HCP",
              },
            ],
          },
          reference: {
            identifier: {
              type: {
                coding: [
                  {
                    code: "urn:oasis:names:tc:xspa:1.0:subject:organization-id",
                  },
                ],
              },
              value: organizationID,
            },
          },
        },
      ],
      purpose: [
        {
          system: "urn:oid:2.16.756.5.30.1.127.3.10.5",
          code: "NORM",
        },
      ],
    },
  } as R4.IConsent);
}

/**
 * Create a FHIR Consent for the HCP Emergency Access level - based on the official template (PolicySet for EPD Setup 202).
 *
 * @param policySetID The PolicySet PolicySetId (with "urn:uuid:" prepended)
 * @param level The access level the HCP is granted
 * @param eprSpid The EPR SPID of the relevant patient
 *
 * @returns FHIR Consent Resource (R4)
 */
export function EmergencyAccessLevel(
  policySetID: string,
  level: PolicySetIdReferenceValue_EmergencyAccessLevel,
  eprSpid: string
): IConsent {
  return new ExtendedConsent({
    resourceType: "Consent",
    meta: {
      profile: ["http://fhir.ch/ig/ch-epr-ppqm/StructureDefinition/PpqmConsent"],
    },
    text: {
      status: "empty",
      div: '&lt;div xmlns="http://www.w3.org/1999/xhtml";&gt;&lt;p&gt;Template 202 - Access in emergency mode&lt;/p&gt;&lt;/div&gt;',
    },
    identifier: [
      {
        type: {
          coding: [
            {
              system: "http://fhir.ch/ig/ch-epr-ppqm/CodeSystem/PpqmConsentIdentifierType",
              code: "policySetId",
            },
          ],
        },
        value: policySetID,
      },
      {
        type: {
          coding: [
            {
              system: "http://fhir.ch/ig/ch-epr-ppqm/CodeSystem/PpqmConsentIdentifierType",
              code: "templateId",
            },
          ],
        },
        value: "202",
      },
    ],
    status: "active",
    scope: {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/consentscope",
          code: "patient-privacy",
        },
      ],
    },
    category: [
      {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
            code: "INFA",
          },
        ],
      },
    ],
    patient: {
      identifier: {
        system: "urn:oid:2.16.756.5.30.1.127.3.10.3",
        value: eprSpid,
      },
    },
    policyRule: {
      coding: [
        {
          code: level,
        },
      ],
    },
    provision: {
      actor: [
        {
          role: {
            coding: [
              {
                system: "urn:oid:2.16.756.5.30.1.127.3.10.6",
                code: "HCP",
              },
            ],
          },
          reference: {
            display: "all",
          },
        },
      ],
      purpose: [
        {
          system: "urn:oid:2.16.756.5.30.1.127.3.10.5",
          code: "EMER",
        },
      ],
    },
  } as R4.IConsent);
}

/**
 * Create a FHIR Consent for the default Access level when HCPs upload documents - based on the official template (PolicySet for EPD Setup 203).
 *
 * @param policySetID The PolicySet PolicySetId (with "urn:uuid:" prepended)
 * @param level The access level the HCP is granted
 * @param eprSpid The EPR SPID of the relevant patient
 *
 * @returns FHIR Consent Resource (R4)
 */
export function PatientProvideLevel(
  policySetID: string,
  level: PolicySetIdReferenceValue_ProvideLevel,
  eprSpid: string
): IConsent {
  return new ExtendedConsent({
    resourceType: "Consent",
    id: "Template203Consent",
    meta: {
      profile: ["http://fhir.ch/ig/ch-epr-ppqm/StructureDefinition/PpqmConsent"],
    },
    text: {
      status: "empty",
      div: '&lt;div xmlns="http://www.w3.org/1999/xhtml";&gt;&lt;p&gt;Template 203 - Default provide level&lt;/p&gt;&lt;/div&gt;',
    },
    identifier: [
      {
        type: {
          coding: [
            {
              system: "http://fhir.ch/ig/ch-epr-ppqm/CodeSystem/PpqmConsentIdentifierType",
              code: "policySetId",
            },
          ],
        },
        value: policySetID,
      },
      {
        type: {
          coding: [
            {
              system: "http://fhir.ch/ig/ch-epr-ppqm/CodeSystem/PpqmConsentIdentifierType",
              code: "templateId",
            },
          ],
        },
        value: "203",
      },
    ],
    status: "active",
    scope: {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/consentscope",
          code: "patient-privacy",
        },
      ],
    },
    category: [
      {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
            code: "INFA",
          },
        ],
      },
    ],
    patient: {
      identifier: {
        system: "urn:oid:2.16.756.5.30.1.127.3.10.3",
        value: eprSpid,
      },
    },
    policyRule: {
      coding: [
        {
          code: level,
        },
      ],
    },
    provision: {
      actor: [
        {
          role: {
            coding: [
              {
                system: "urn:oid:2.16.756.5.30.1.127.3.10.6",
                code: "HCP",
              },
            ],
          },
          reference: {
            display: "all",
          },
        },
      ],
      purpose: [
        {
          system: "urn:oid:2.16.756.5.30.1.127.3.10.5",
          code: "NORM",
        },
        {
          system: "urn:oid:2.16.756.5.30.1.127.3.10.5",
          code: "AUTO",
        },
      ],
    },
  } as R4.IConsent);
}
