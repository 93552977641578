import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TitleMixin extends Vue {
  activated() {
    if (this.$route.meta?.title) {
      document.title = `${this.$t("meta.appName")} | ${this.$t(this.$route.meta.title)}`;
    } else {
      document.title = `${this.$t("meta.appName")}`;
    }
  }

  mounted() {
    if (this.$route.meta?.title) {
      document.title = `${this.$t("meta.appName")} | ${this.$t(this.$route.meta.title)}`;
    } else {
      document.title = `${this.$t("meta.appName")}`;
    }
  }
}
