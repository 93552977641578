import Vue from "vue";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import AccountModule from "@/store/modules/account";

import Store from "../index";
import { R4 } from "@ahryman40k/ts-fhir-types";
import config from "@/config";

import i18n from "@/i18n";

const accountState: AccountModule = getModule(AccountModule);
const authHeaderFunction = async function(): Promise<string | null> {
  try {
    return accountState.getAuthenticationHeader();
  } catch (error) {
    Vue.notify({
      text: i18n.t("toasts.auth.tokenFetchFail").toString(),
      type: "error",
      duration: -1,
    });
    return null;
  }
};

@Module({
  dynamic: true,
  store: Store,
  name: "hcprovider",
  namespaced: true,
})
export default class HCProviderModule extends VuexModule {
  public currentHCP: R4.IOrganization | R4.IPractitionerRole | null = null;
  // The HCPs associated with certain consents
  public consentHCPs: Map<string, R4.IOrganization | R4.IPractitionerRole> = new Map();
  public isLoading: boolean = false;

  // Holds the HCP-IDs that have been selected by HCPs in the Professional Portal and been given delegation rights
  public alreadySelectedHCPsForDelegationThisSession: string[] = [];

  @Mutation
  setCurrentHCP(hcp: R4.IOrganization | R4.IPractitionerRole) {
    this.currentHCP = hcp;
  }

  @Mutation
  clearCurrentHCP() {
    this.currentHCP = null;
  }

  @Mutation
  clearConsentHCPs() {
    this.consentHCPs = new Map();
  }

  @Mutation
  clearSessionHCPs() {
    this.alreadySelectedHCPsForDelegationThisSession = [];
  }

  @Mutation
  addDelegationHCP(id: string) {
    this.alreadySelectedHCPsForDelegationThisSession.push(id);
  }

  @Mutation
  clearAll() {
    this.clearConsentHCPs();
    this.clearCurrentHCP();
    this.clearSessionHCPs();
  }

  @Mutation
  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  setConsentHCPs(consentHCPs: Map<string, R4.IOrganization | R4.IPractitionerRole>) {
    this.consentHCPs = consentHCPs;
  }

  @Action({ rawError: true })
  async fetchConsentHCPs(searches: ConsentHCPSearch[]) {
    this.setLoading(true);
    const practitioners = searches.filter(search => search.isPractitioner);
    const organizations = searches.filter(search => !search.isPractitioner);

    let consentHCPs: (R4.IOrganization | R4.IPractitionerRole)[] = [];

    if (practitioners.length > 0) {
      let practitionerURL = config.r4.practitionerRole;
      practitionerURL += "?_include=" + "PractitionerRole:practitioner";
      practitionerURL += "&_include=" + "PractitionerRole:location";
      practitionerURL +=
        "&practitioner.identifier=" + practitioners.map(p => p.identifier).join(",");

      const authHeader = await authHeaderFunction();
      if (authHeader == null) {
        this.setConsentHCPs(new Map());
        this.setLoading(false);
        throw new Error("toasts.auth.authHeaderFail");
      }

      const response = await window.fetch(practitionerURL, {
        method: "GET",
        headers: {
          Authorization: authHeader,
        },
      });

      if (!response.ok) {
        this.setConsentHCPs(new Map());
        this.setLoading(false);
        throw new Error("toasts.documentAccess.overview.practitionerFetchFail");
      }

      const bundle = (await response.json()) as R4.IBundle;
      if (bundle.total && bundle.total > 0 && bundle.entry) {
        const entries = bundle.entry.map(entry => entry.resource as R4.IPractitionerRole);
        consentHCPs = consentHCPs.concat(entries);
      }
    }

    if (organizations.length > 0) {
      let organizationURL = config.r4.organization;
      organizationURL +=
        "?identifier=" + organizations.map(o => o.identifier.replace("urn:oid:", "")).join(",");

      const authHeader = await authHeaderFunction();
      if (authHeader == null) {
        this.setConsentHCPs(new Map());
        this.setLoading(false);
        throw new Error("toasts.auth.authHeaderFail");
      }

      const response = await window.fetch(organizationURL, {
        method: "GET",
        headers: {
          Authorization: authHeader,
        },
      });

      if (!response.ok) {
        this.setConsentHCPs(new Map());
        this.setLoading(false);
        throw new Error("toasts.documentAccess.overview.organizationsFetchFail");
      }

      const bundle = (await response.json()) as R4.IBundle;
      if (bundle.total && bundle.total > 0 && bundle.entry) {
        const entries = bundle.entry.map(entry => entry.resource as R4.IOrganization);
        consentHCPs = consentHCPs.concat(entries);
      }
    }

    if (consentHCPs.length > 0) {
      const returnMap: Map<string, R4.IOrganization | R4.IPractitionerRole> = new Map();
      consentHCPs.forEach(hcp => {
        const extractedID = extractIdentifier(hcp);
        if (extractedID) {
          returnMap.set(extractedID, hcp);
        }
      });

      this.setConsentHCPs(returnMap);
      // Explicitly not set loading to false here due to further post processing at call location
      return;
    }

    // Explicitly not set loading to false here due to further post processing at call location
    this.setConsentHCPs(new Map());
  }
}

export const extractIdentifier = function(
  hcp: R4.IOrganization | R4.IPractitionerRole
): string | undefined {
  switch (hcp.resourceType) {
    case "Organization": {
      const identifier = hcp.identifier?.find(id => id.system == "OID")?.value;
      return identifier;
    }
    case "PractitionerRole":
      if (hcp.contained) {
        const practitionerReference = hcp.practitioner?.reference?.replace("#", "");
        if (practitionerReference) {
          const containedPractitioner = hcp.contained.find(c => c.id == practitionerReference) as
            | R4.IPractitioner
            | undefined;
          if (containedPractitioner) {
            const identifier = containedPractitioner.identifier?.find(id => id.system == "GLN")
              ?.value;
            return identifier;
          }
        }
      }
  }

  return undefined;
};

export interface ConsentHCPSearch {
  isPractitioner: boolean;
  identifier: string;
}
