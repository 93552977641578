export enum AuditTrailConsumptionEventType {
  DOC_CREATE = "ATC_DOC_CREATE",
  DOC_READ = "ATC_DOC_READ",
  DOC_UPDATE = "ATC_DOC_UPDATE",
  DOC_DELETE = "ATC_DOC_DELETE",
  DOC_SEARCH = "ATC_DOC_SEARCH",
  POL_CREATE_AUT_PART_AL = "ATC_POL_CREATE_AUT_PART_AL",
  POL_UPDATE_AUT_PART_AL = "ATC_POL_UPDATE_AUT_PART_AL",
  POL_REMOVE_AUT_PART_AL = "ATC_POL_REMOVE_AUT_PART_AL",
  POL_DEF_CONFLEVEL = "ATC_POL_DEF_CONFLEVEL",
  POL_DIS_EMER_USE = "ATC_POL_DIS_EMER_USE",
  POL_ENA_EMER_USE = "ATC_POL_ENA_EMER_USE",
  POL_INCL_BLACKLIST = "ATC_POL_INCL_BLACKLIST",
  POL_EXL_BLACKLIST = "ATC_POL_EXL_BLACKLIST",
  LOG_READ = "ATC_LOG_READ",
  HPD_GROUP_ENTRY_NOTIFIY = "ATC_HPD_GROUP_ENTRY_NOTIFY",
}

export enum EprPurposeOfUse {
  EMER = "EMER",
  NORM = "NORM",
  AUTO = "AUTO",
}

export enum EprParticipant {
  // urn:oid:2.16.756.5.30.1.127.3.10.6
  PAT = "PAT",
  HCP = "HCP",
  ASS = "ASS",
  REP = "REP",
  TCU = "TCU",
  PADM = "PADM",
  DADM = "DADM",
  // urn:oid:2.16.756.5.30.1.127.3.10.8
  GRP = "GRP",
}

export enum DocumentAuditEventType {
  DOC_CREATE = "ATC_DOC_CREATE",
  DOC_READ = "ATC_DOC_READ",
  DOC_UPDATE = "ATC_DOC_UPDATE",
  DOC_DELETE = "ATC_DOC_DELETE",
  DOC_SEARCH = "ATC_DOC_SEARCH",
}

export enum AuditEventType {
  DocumentAuditEventType,
  PolicyAuditEventType,
  AuditTrailEventType,
  HpdGroupEntryEventType,
}

export enum DocumentAuditEventDetailTypes {
  REPOSITORY_UNIQUE_ID = "Repository Unique Id",
  HOME_COMMUNITY_ID = "homeCommunityID",
  EPR_DOCUMENT_TYPE_CODE = "EprDocumentTypeCode",
}

export enum PolicyAuditEventDetailTypes {
  ACCESS_LEVEL = "AccessLevel",
  ACCESS_LIMITED_TO_DATE = "AccessLimitedToDate",
  PROVIDE_LEVEL = "ProvideLevel",
}
