import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { getModule } from "vuex-module-decorators";
import AccountModule from "@/store/modules/account";
import { Role } from "@/global";
import timelineRoutes from "./timeline";
import documentAccessRoutes from "./documentAccess";
import accessLogsRoutes from "./accessLogs";
import i18n from "@/i18n";

Vue.use(VueRouter);

const accountState = getModule(AccountModule);

const routes: RouteConfig[] = [
  {
    path: "/",
    redirect: { name: "login" },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "meta.login",
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/timeline",
    component: () => import(/* webpackChunkName: "timeline" */ "../views/Timeline.vue"),
    children: timelineRoutes,
  },
  {
    path: "/documentAccess",
    component: () => import(/* webpackChunkName: "documentAccess" */ "../views/DocumentAccess.vue"),
    children: documentAccessRoutes,
  },
  {
    path: "/accessLogs",
    component: () => import(/* webpackChunkName: "accessLogs" */ "../views/AccessLogs.vue"),
    children: accessLogsRoutes,
  },
  {
    path: "/emergencyAccess",
    name: "emergencyAccess",
    meta: {
      title: "meta.emergencyAccess",
    },
    component: () =>
      import(/* webpackChunkName: "emergencyAccess" */ "../views/EmergencyAccess.vue"),
  },
  {
    path: "/patients",
    name: "patients",
    meta: {
      title: "meta.patients",
      authorize: [Role.Assistant, Role.HealthcareProfessional],
    },
    component: () => import(/* webpackChunkName: "patients" */ "../views/PatientSearchView.vue"),
  },
  {
    path: "/legal",
    name: "legal",
    meta: {
      title: "meta.legal",
    },
    component: () => import(/* webpackChunkName: "legal" */ "../views/Legal.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      title: "meta.privacy",
    },
    component: () => import(/* webpackChunkName: "legal" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/settings",
    name: "userSettings",
    meta: {
      title: "meta.userSettings",
      authorize: [Role.Patient, Role.Representative],
      tile: "userSettings",
    },
    component: () => import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
  },
  {
    path: "*",
    redirect: { name: "login" },
  },
];

const scrollBehavior = function (to: any, from: any, savedPosition: any | undefined) {
  // return desired position
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition;
  } else {
    const position: { x?: number; y?: number } = {};
    // check if any matched route config has meta that requires scrolling to top
    if (to.matched.some((m: any) => m.meta.scrollToTop)) {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      position.x = 0;
      position.y = 0;
    }

    return position;
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

router.beforeEach((to: Route, from: Route, next) => {
  document.title = to.meta?.title || "phellow:web";

  const authorize = to.meta?.authorize as string[] | undefined;
  const hasValidToken = accountState.hasValidToken;
  const currentRole = accountState.role;

  if (authorize) {
    // checks for the presence of the authorize field. Any route that does not contain this
    // at all, does not require authentication.
    if (!hasValidToken) {
      // Not logged in, so redirect to login page
      accountState.setRedirectPath(to.fullPath);
      return next({ name: "login", query: { redirect: to.fullPath } }); // query: { returnUrl: to.path}
    }

    accountState.startSessionPoller();

    // check if route is restricted by role
    if (authorize.length > 0 && !authorize.includes(currentRole)) {
      Vue.notify({
        text: i18n.t("toasts.account.noAppropriatePermission").toString(),
        duration: -1,
      });

      if (from.name) {
        return next(false);
      } else {
        switch (currentRole) {
          case Role.HealthcareProfessional:
          case Role.Assistant:
            return next({ name: "patients" });
          case Role.Patient:
          case Role.Representative:
            return next({ name: "timeline" });
        }
      }

      return next("login");
    }
  }

  if (to.name == "timeline" && from.name == "vaccination") {
    if (!to.params) {
      to.params = {};
    }

    to.params._reload = "true";
  }

  // Everything is fine, continue
  next();
});

export default router;
