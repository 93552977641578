
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import Icon from "@/components/icons/AbilisText.vue";
import { LogOutIcon } from "vue-feather-icons";
import dayjs from "dayjs";
// Do NOT remove the below imports, otherwise the localizedFormat methods won't work.
import "dayjs/locale/de-ch";
import "dayjs/locale/fr-ch";
import "dayjs/locale/it-ch";
// Do NOT remove the above imports, otherwise the localizedFormat methods won't work.

// eslint-disable-next-line no-unused-vars
import { TranslateResult } from "vue-i18n";
import { Role } from "@/global";

@Component({
  components: { Icon, LogOutIcon },
})
export default class Navbar extends Vue {
  @Prop({ default: Role.Patient }) private readonly role!: Role;
  @Prop({ default: false }) private readonly hasValidToken!: boolean;
  @Prop({}) private readonly expiresAt!: string | null;
  @Prop({}) private readonly availableLanguages!: string[];
  @Prop({ default: false }) private readonly hasEmergencyAccess!: boolean;
  @Prop({ default: false }) private readonly disableSessionContraints!: boolean;

  private countdown: { display: string; datetime: string } = { display: "", datetime: "" };
  private accessibleCountdown: { display: string; datetime: string } = {
    display: "",
    datetime: "",
  };

  private timer: number | null = null;
  private accessibleTimer: number | null = null;

  created() {
    if (this.expiresAt) {
      this.setupLogoutTimer();
    }
  }

  destroyed() {
    this.clearTimer();
  }

  changeLanguage(languageCode: string) {
    this.$i18n.locale = languageCode;
    const shortLocale = this.$i18n.locale.split("-")[0];
    if ((window as any).mtcaptchaConfig) {
      (window as any).mtcaptchaConfig.lang = shortLocale;
    }

    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", shortLocale);

    window.localStorage.setItem("phellow:language", this.$i18n.locale);

    if (this.$route.name == "legal" || this.$route.name == "privacy") {
      this.$router.replace({
        name: this.$route.name!,
        query: { language: shortLocale },
      });
    }
  }

  private setupLogoutTimer() {
    if (this.timer) {
      return;
    }

    if (!this.disableSessionContraints && this.expiresAt) {
      const timer = () => {
        if (this.expiresAt) {
          const now = dayjs();
          var expirationDate = dayjs(this.expiresAt);

          if (now.isAfter(expirationDate)) {
            return this.updateSession();
          }

          const remaining = dayjs(expirationDate.diff(now));

          this.countdown = {
            display: remaining.locale(this.$i18n.locale).format("m:ss"),
            datetime: remaining.locale(this.$i18n.locale).format("[PT]m[M]s[S]"),
          };
        }
      };

      timer();
      this.timer = window.setInterval(timer, 500);

      this.accessibleCountdown = this.countdown;

      this.accessibleTimer = window.setInterval(() => {
        this.accessibleCountdown = this.countdown;
      }, 60000);
    }
  }

  onHome() {
    if (this.$route.name !== this.home) {
      this.$router.push({ name: this.home });
    }
  }

  get home(): string {
    if (this.hasValidToken) {
      switch (this.role) {
        case Role.Patient:
        case Role.Representative:
          return "timeline";
        case Role.HealthcareProfessional:
        case Role.Assistant:
          return "patients";
      }
    }
    return "login";
  }

  @Emit()
  updateSession() {}

  @Emit()
  logout() {
    this.clearTimer();
  }

  @Watch("expiresAt")
  private onExpiresAtChanged(
    newValue: string | null,
    // eslint-disable-next-line no-unused-vars
    oldValue: string | null
  ): void {
    // this.clearTimer();

    if (newValue) {
      this.setupLogoutTimer();
    } else {
      this.clearTimer();
      this.countdown = { display: "", datetime: "" };
    }
  }

  private clearTimer(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    if (this.accessibleTimer) {
      clearInterval(this.accessibleTimer);
      this.accessibleTimer = null;
    }
  }

  get title(): TranslateResult {
    switch (this.role) {
      case Role.Patient:
        return this.$t("navbar.titlePatient");
      case Role.Representative:
        return this.$t("navbar.titleRepresentative");
      case Role.HealthcareProfessional:
        return this.$t("navbar.titleHealthcareprofessional");
      case Role.Assistant:
        return this.$t("navbar.titleAssistant");
      default:
        return "";
    }
  }

  get coloredIcon(): boolean {
    switch (this.role) {
      case Role.None:
      case Role.HealthcareProfessional:
      case Role.Assistant:
      case Role.Representative:
        return false;
      case Role.Patient:
        return true;
      default:
        return true;
    }
  }

  get sectionClass(): Object {
    switch (this.role) {
      case Role.None:
        return { "bg-red-normal": true, "text-white": true };
      case Role.HealthcareProfessional:
        return { "bg-hcp": true, "text-white": true };
      case Role.Patient:
        return { "bg-patient": true, "text-darkblue-100": true };
      case Role.Assistant:
        return { "bg-assistant": true, "text-white": true };
      case Role.Representative:
        return { "bg-representative": true, "text-white": true };
      default:
        return { "bg-red-normal": true, "text-white": true };
    }
  }

  languageButtonClass(language: string): Object {
    const currentLanguage = this.$i18n.locale || "de-ch";
    switch (this.role) {
      case Role.None:
      case Role.HealthcareProfessional:
      case Role.Assistant:
      case Role.Representative:
        return { "text-white": true, underline: currentLanguage == language };
      case Role.Patient:
        return { "text-darkblue-100": true, underline: currentLanguage == language };
      default:
        return { "text-white": true, underline: currentLanguage == language };
    }
  }
}
