
import { Component, Vue } from "vue-property-decorator";
import config from "@/config";
import { Role } from "@/global";

@Component
export default class Footer extends Vue {
  private showBuildNumber: boolean = false;

  get language(): string {
    return this.$i18n.locale.split("-")[0];
  }

  get isPatientPortal(): boolean {
    return config.role == Role.Patient || config.role == Role.Representative;
  }

  get version(): string | null {
    return config?.debug?.versionTag || null;
  }

  get buildNumber(): string | null {
    return config?.debug?.commitHash || null;
  }

  get versionString(): string | null {
    if (this.version && this.buildNumber && this.showBuildNumber)
      return `${this.version} (${this.buildNumber})`;
    else if (this.version) return this.version;
    else if (this.buildNumber) return this.buildNumber;
    else return null;
  }
}
