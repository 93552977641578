import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { merge } from "lodash";
import config from "./config";
Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1].toLocaleLowerCase().replace("_", "-");

      messages[locale] = merge(locales(key), config.i18n.override[locale]);
    }
  });

  return merge(messages);
}

function matchingLocale(language: string, availableLanguages: string[]): string {
  for (let index = 0; index < availableLanguages.length; index++) {
    const lang = availableLanguages[index];

    if (lang.includes(language.replace(/-\w+/, ""))) {
      return lang;
    }
  }
  return language;
}

const dateTimeFormats: VueI18n.DateTimeFormats = {
  de: {
    date: { year: "numeric", month: "long", day: "numeric" },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    time: { hour: "numeric", minute: "numeric", second: "numeric" },
  },
  en: {
    date: { year: "numeric", month: "long", day: "numeric" },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    time: { hour: "numeric", minute: "numeric", second: "numeric" },
  },
  fr: {
    date: { year: "numeric", month: "long", day: "numeric" },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    time: { hour: "numeric", minute: "numeric", second: "numeric" },
  },
  it: {
    date: { year: "numeric", month: "long", day: "numeric" },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    time: { hour: "numeric", minute: "numeric", second: "numeric" },
  },
};

let locale =
  window.localStorage.getItem("phellow:language") || navigator.language?.toLowerCase() || "de";
if (locale.split("-")[0] == "en") {
  locale = "de";
}

const vuei18n = new VueI18n({
  locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de",
  messages: loadLocaleMessages(),
  dateTimeFormats,
});

vuei18n.locale = matchingLocale(locale, vuei18n.availableLocales);

export default vuei18n;
